@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/NotoSansCJKkr-Thin.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/NotoSansCJKkr-Light.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/NotoSansCJKkr-Regular.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/NotoSansCJKkr-Medium.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/NotoSansCJKkr-Bold.otf');
}
@font-face {
    font-family: 'Noto Sans CJK KR';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/NotoSansCJKkr-Black.otf');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Poppins-Medium.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Poppins-Light.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/Poppins-SemiBold.ttf');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Poppins-Bold.ttf');
}
